import React, { useEffect } from 'react';
import Home from './Home/Home';
import Login from './components/Login';
import Admin from './Admin/Admin';
import UserLink from "./UserLink/UserLink";
import TimeOut from "./TimeOut/TimeOut";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider, useSelector } from "react-redux";
import "./style/css/App.css";
import store from "./store";
import { createHashHistory } from 'history'
import SecuredRoute from './securityUtils/SecuredRoute';
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "./actions/types";
import 'react-toastify/dist/ReactToastify.css';
import "./style/css/Alertify.css";
import "./style/css/Modals.css";
import "./style/css/Toast.css";
import { setCompany } from "./redux-actions/adminActions";

export const history = createHashHistory();
export const App = () => {
  const company = useSelector(state => state.admin.company);

  useEffect(() => {
    if(company){
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${process.env.PUBLIC_URL}/info.png`;
      document.getElementsByTagName('head')[0].appendChild(link);
      
    }else{
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${process.env.PUBLIC_URL}/favicon_64.png`;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    
  }, [company]);
  
  
  const jwtToken = cookie.load("token");
if (jwtToken) {
  const decoded_jwtToken = jwt_decode(jwtToken);
  if(window.location.hash == "#/"){
    window.location.href = "#/admin";
  }
  
  
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decoded_jwtToken
  });
}

  const infoProjekt = process.env.REACT_APP_INFO_PROJEKT;
  
  if(window.location.hostname.includes(infoProjekt)){
    document.body.dataset.theme = 'light';
    store.dispatch(setCompany(true));
  }else{
    document.body.dataset.theme = 'dark';
    store.dispatch(setCompany(false));
  }
  return (
    <Provider store={store}>
    <Router history={history}>
        
        <div className="App">
        
          <div id="main-container">                      
          <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <SecuredRoute exact path="/admin" component={Admin} />
          <Route exact path="/link/:hash" component={UserLink} />
          <Route exact path="/timeOut" component={TimeOut} />
          </Switch>
          </div>

        </div>
        </Router>
    </Provider>
  );
}
